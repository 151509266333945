<template>
  <a-page-header
    style="border: 1px solid rgb(235, 237, 240)"
    :title="state.ifAdd ? '创建' : '编辑'"
    @back="
      () =>
        $router.push({
          name: 'Home',
          query: {
            search: state.submitData.name,
          },
        })
    "
  />
  <section class="editContain">
    <section style="position: relative">
      <p>
        名称：<span class="tips" v-if="state.showTips"
          >(已有当前百科，<span class="active" @click="replaceForm"
            >点击切换编辑</span
          >)</span
        >
      </p>
      <a-input
        v-model:value="state.submitData.name"
        placeholder="请输入名称"
        @blur="checkName"
      />
    </section>
    <section>
      <p>范围：</p>
      <section>
        <a-input v-model:value="state.submitData.scope" />
      </section>
    </section>
    <section>
      <p>同义词：</p>
      <a-input v-model:value="state.submitData.aliases" />
    </section>
    <section>
      <p>标签：</p>
      <a-input v-model:value="state.submitData.tags" />
    </section>
    <section>
      <p>来源：</p>
      <a-input v-model:value="state.submitData.source" />
    </section>
    <section>
      <p>内容：</p>
      <a-textarea
        v-model:value="state.submitData.content"
        :rows="5"
        allow-clear
      />
    </section>
    <section>
      <p>备注：</p>
      <a-textarea v-model:value="state.submitData.note" :rows="2" allow-clear />
    </section>
    <section>
      <a-button
        type="primary"
        block
        style="margin: 12px 0"
        @click="createEntry"
      >
        提交
      </a-button>
    </section>
  </section>
</template>

<script>
import { reactive, toRaw } from '@vue/reactivity'
import kg from '@/api/kg'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

export default {
  name: 'Edit',
  setup() {
    const state = reactive({
      submitData: {
        name: '',
        scope: '',
        aliases: '',
        tags: '',
        source: '',
        content: '',
        note: '',
        rid: '',
      },
      ifAdd: true,
      showTips: false,
      recordResponse: {},
    })
    const router = useRouter()
    const createEntry = async () => {
      // console.log(toRaw(state.submitData))
      let response
      if (state.ifAdd) {
        response = await kg.ekb.create(
          'ml',
          state.submitData.name,
          state.submitData.scope,
          state.submitData.aliases ? state.submitData.aliases.split(',') : [],
          state.submitData.tags ? state.submitData.tags.split(',') : [],
          state.submitData.source,
          state.submitData.content,
          state.submitData.note
        )
      } else {
        response = await kg.ekb.update(
          'ml',
          state.submitData.name,
          state.submitData.scope,
          state.submitData.aliases ? state.submitData.aliases.split(',') : [],
          state.submitData.tags ? state.submitData.tags.split(',') : [],
          state.submitData.source,
          state.submitData.content,
          state.submitData.note,
          state.submitData.rid
        )
      }

      // console.log(response)
      if (response.success) {
        message.info(state.ifAdd ? '创建成功' : '更新成功')
        router.push({
          name: 'Home',
          query: {
            search: state.submitData.name,
          },
        })
      } else {
        message.error(state.ifAdd ? '创建失败' : '更新失败')
      }
    }
    const checkName = async () => {
      if (!state.ifAdd) {
        return
      }
      if (state.submitData.name.trim()) {
        const response = await kg.ekb.check('ml', state.submitData.name)
        // console.log(response)
        if (response.success) {
          state.showTips = true
          state.recordResponse = response
        }
      }
    }
    const replaceForm = () => {
      let response = state.recordResponse
      state.submitData.name = response.data[0].__name
      state.submitData.scope = response.data[0].__scope
      state.submitData.aliases = response.data[0].__aliases
        ? response.data[0].__aliases.join(',')
        : ''
      state.submitData.tags = response.data[0].__tags
        ? response.data[0].__tags.join(',')
        : ''
      state.submitData.source = response.data[0].source
      state.submitData.content = response.data[0].content
      state.submitData.note = response.data[0].note
      state.submitData.rid = response.data[0]['@rid']
      state.ifAdd = false
    }
    return {
      state,
      createEntry,
      checkName,
      replaceForm,
    }
  },
  mounted() {
    if (this.$route.query.rid) {
      this.state.ifAdd = false
      this.state.submitData = { ...this.$route.query }
    } else {
      this.state.ifAdd = true
      this.state.submitData.name = this.$route.query.name
    }
  },
}
</script>

<style lang="less" scoped>
.editContain {
  padding: 10px;
  height: calc(100vh - 70px);
  overflow: auto;
  p {
    height: 32px;
    line-height: 32px;
    margin: 0;
  }
  .tips {
    // position: absolute;
    font-size: 12px;
    color: #999;
    .active {
      color: #3884ff;
      cursor: pointer;
    }
  }
}
</style>